import React from 'react'
import Header from '../../header/header.component';
import ContentContainer from '../content/content-container.component';
import Footer from '../../footer/footer.component';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const MainWrapper = styled.div.attrs({
    className: "container mx-auto mt-2 mb-2"
})`
    
`;


function PageContainer({children}) {
  return (
    <MainWrapper>
        <Header />
        <ContentContainer>
          {children}
        </ContentContainer>
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          />
    </MainWrapper>
  )
}

export default PageContainer