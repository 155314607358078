import React, { useEffect } from 'react'
import PageTemplate from '../components/body/page-template.component'
import PageContainer from '../components/container/page/page-container.component'
// import { toast } from 'react-toastify';

function WelcomePage() {

  useEffect(
    () => {
      // error, warn, info
      // toast.success('Wow so easy!');
  }, []);


  return (
    <PageContainer>
      <PageTemplate pageName = "Home Page" />
    </PageContainer>
  )
}

export default WelcomePage